/* eslint-disable */
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import PropTypes from 'prop-types';
import { FORMAT_GRAPH_DATA, numberToCurrency } from 'utilities';
import { Link } from 'gatsby';
import {
  Banner,
  TourCard,
} from 'components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  BestInNZ,
  TourList,
  TestimonialList,
  InstagramList,
} from 'layouts';
import { BASE_URL } from 'config';

class BarTabAndBungyGiveaway extends PureComponent {
  constructor(props) {
    super(props);
    const TOURS = FORMAT_GRAPH_DATA(props.data.allTourPages);
    const REVIEWS = FORMAT_GRAPH_DATA(props.data.allReviews);
    this.state = {
      tours: TOURS,
      reviews: REVIEWS,
    };
  }

  render() {
    const { tours, reviews } = this.state;
    return (
      <Layout page="bar-tab-and-bungy-giveaway">

        <Helmet
          title="Haka Snow Tours Giveaway - Bar Tab & Bungy"
          meta={[
            {
              name: 'description', content: 'To celebrate the NZ snow season we’ve got some epic Queenstown giveaway packs up for grabs.',
            },
            { name: 'og:title', content: 'Haka Snow Tours Giveaway - Bar Tab & Bungy' },
            {
              name: 'og:description', content: 'To celebrate the NZ snow season we’ve got some epic Queenstown giveaway packs up for grabs.',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/bar-tab-and-bungy-giveaway/` }]}
        />

        <Banner backgroundImage="https://static-hakatours.imgix.net/Ledge-Bungy-4.jpg?h=490&bm=screen&fit=crop&auto=format">
          <h2 className="c-heading c-heading--h0">SNOW GIVEAWAY - BAR TAB & BUNGY</h2>
        </Banner>
        <section className="l-section">
          <div className="l-container">
            <h1 className="l-contact-us__title c-heading c-heading--h2 u-text--center">GET A FREE $50 BAR TAB & LEDGE BUNGY</h1>
            <p className="u-text--center">
              Hurrah the New Zealand snow season is now in full swing, with NZ’s South Island mountains in mint condition.
            </p>
            <p className="u-text--center">
              To celebrate we’ve teamed up with our friends in Queenstown to create a sensational snow  giveaway - WORLD BAR Queenstown (the ultimate spot for afternoon drinks,
              good honest food, teapot cocktails, craft beer and all sorts of quirky events) for a <a href="https://www.theworldbar.co.nz/" target="_blank">$50 bar tab</a> and world renowned <a href="https://www.bungy.co.nz/queenstown/ledge/ledge-bungy/" target="_blank">AJ Hackett’s LEDGE BUNGY</a>
              – where you can fling yourself off the edge of their platform high above Queenstown. Please note these two activities cannot be combined for obvious reasons!
            </p>
            <br /><br />
            <h2 className="l-contact-us__title c-heading c-heading--h2 u-text--center">The Details</h2>
            <p className="u-text--center">
              Get a FREE $50 Bar Tab for <a href="https://www.theworldbar.co.nz/" target="_blank">World Bar Queenstown</a> and
              1 x <a href="https://www.bungy.co.nz/queenstown/ledge/ledge-bungy/" target="_blank">AJ Hackett Ledge Bungy</a> (worth $205)!
              To redeem, just be one of the next 5 bookings of a <a href="https://hakatours.com/new-zealand-snow-tours/south-island-snow-safari/" target="_blank">Haka Snow Safari</a> or
              <a href="https://hakatours.com/new-zealand-snow-tours/south-island-club-fields/" target="_blank"> Haka Snow Odyssey tours*</a>.
              Hurry - first come first serve, terms & conditions apply.
            </p>
            <br /><br />
            <h2 className="l-contact-us__title c-heading c-heading--h2 u-text--center">Book your Haka Snow Tours</h2>
          </div>
          <div className="l-container l-container--small">
            <div className="l-bungy-tour-list__grid">
              {tours.filter(tour => tour.category_ids.includes("560b2d27a6af4800ae000176")).map(item => (
                <TourCard
                  key={item.id}
                  data={item}
                />
              ))}
            </div>
            <p style={{ textDecoration: 'underline' }}>Terms & Conditions*</p>
            <p>
              The Queenstown Giveaway Pack is valid for the first 5 Haka Snow Safari or Snow Odyssey bookings only, when booked between 14-21 August 2019.
              Valid for travel in 2019. Excludes Snow Explorer and Ultimate Heli Tour. The Prize is not transferable, exchangeable or negotiable and are not redeemable for cash.
              Must be over 18 to travel with Haka Tours and meet AJ Hackets’s conditions  <a href="https://www.bungy.co.nz/queenstown/ledge/ledge-bungy/" target="_blank">https://www.bungy.co.nz/queenstown/ledge/ledge-bungy/</a>.
            </p>
          </div>
        </section>
      </Layout>
    );
  }
}

BarTabAndBungyGiveaway.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      sort: {fields: [order], order: ASC},
      filter: {
        id: {
          in: ["Tour__Pages__560b2ceda6af4800ae000151","Tour__Pages__560b2cd1a6af4800ae000136"]
        }
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          slug
          order
          is_popular
          destination_ids
          destination_groups
          category_ids
          starting_price
          number_of_days
          background_color
          short_description_markdown
          parent_name
          absolute_slug
          picture_id
          small_map_id
          sliding_first_picture_id
          sliding_second_picture_id
          sliding_third_picture_id
          sliding_fourth_picture_id
          sliding_fifth_picture_id
          sliding_sixth_picture_id
          sliding_seventh_picture_id
          sliding_eighth_picture_id
          sliding_ninth_picture_id
          sliding_tenth_picture_id
          category_icons {
            id
            name
            tooltip
            svg_text
          }
          parent_category_icon {
            id
            svg_text
          }
        }
      }
    }

    allReviews(
      limit: 3,
      sort: {
        fields: date_published,
        order: DESC
      }
      filter: {
        date_published: {
          ne: null
        }
      }
    ) {
      edges {
        node {
          id
          api_id
          author
          country
          text
          image_url
          date_published
        }
      }
    }
  }
`;

export default BarTabAndBungyGiveaway;
